import "core-js/modules/es.array.find";import "core-js/modules/es.array.for-each";import "core-js/modules/es.array.splice";import "core-js/modules/es.function.name";import "core-js/modules/web.dom-collections.for-each"; //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    src: {
      type: String,
      required: true },

    autoUpload: {
      type: Boolean,
      "default": true } },


  data: function data() {
    return {
      status: 'ready',
      files: [],
      point: {},
      uploading: false,
      percent: 0 };

  },
  methods: {
    add: function add() {
      this.$refs.file.click();
    },
    submit: function submit() {var _this = this;
      if (this.files.length === 0) {
        console.warn('no file!');
        return;
      }
      var formData = new FormData();
      this.files.forEach(function (item) {
        formData.append(item.name, item.file);
      });
      var xhr = new XMLHttpRequest();
      xhr.upload.addEventListener('progress', this.uploadProgress, false);
      xhr.open('POST', this.src, true);
      this.uploading = true;
      xhr.send(formData);
      xhr.onload = function () {
        _this.uploading = false;
        if (xhr.status === 200 || xhr.status === 304) {
          _this.status = 'finished';
          console.log('upload success!');
        } else {
          console.log("error\uFF1Aerror code ".concat(xhr.status));
        }
      };
    },
    submitOne: function submitOne() {var _this2 = this;
      var file = this.$refs.file.files[0];
      console.log(file);

      var formData = new FormData();
      formData.append(file, file);
      var xhr = new XMLHttpRequest();
      xhr.upload.addEventListener('progress', this.uploadProgress, false);
      xhr.open('POST', this.src, true);
      this.uploading = true;
      xhr.send(formData);
      xhr.onload = function () {
        _this2.uploading = false;
        if (xhr.status === 200 || xhr.status === 304) {
          _this2.status = 'finished';
          console.log('upload success!');
        } else {
          console.log("error\uFF1Aerror code ".concat(xhr.status));
        }
      };
    },
    finished: function finished() {
      this.files = [];
      this.status = 'ready';
    },
    remove: function remove(index) {
      this.files.splice(index, 1);
    },
    fileChanged: function fileChanged() {
      if (this.autoUpload) {
        this.submitOne();
        return;
      }
      var list = this.$refs.file.files;
      for (var i = 0; i < list.length; i++) {
        if (!this.isContain(list[i])) {
          var item = {
            name: list[i].name,
            size: list[i].size,
            file: list[i] };

          this.html5Reader(list[i], item);
          console.log(2);
          this.files.push(item);
          // 这里单张上传
        }
      }
      this.$refs.file.value = '';
    },
    // 将图片文件转成BASE64格式
    html5Reader: function html5Reader(file, item) {var _this3 = this;
      var reader = new FileReader();
      reader.onload = function (e) {
        _this3.$set(item, 'src', e.target.result);
      };
      reader.readAsDataURL(file);
    },
    isContain: function isContain(file) {
      return this.files.find(
      function (item) {return item.name === file.name && item.size === file.size;});

    },
    uploadProgress: function uploadProgress(evt) {
      var component = this;
      if (evt.lengthComputable) {
        var percentComplete = Math.round(evt.loaded * 100 / evt.total);
        component.percent = percentComplete / 100;
      } else {
        console.warn('upload progress unable to compute');
      }
    } } };