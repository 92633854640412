var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "vue-uploader" }, [
    _c(
      "div",
      { staticClass: "file-list" },
      [
        _vm._l(_vm.files, function(file, index) {
          return _c(
            "section",
            { key: index, staticClass: "file-item draggable-item" },
            [
              _c("img", {
                attrs: { src: file.src, alt: "", ondragstart: "return false;" }
              }),
              _c(
                "span",
                {
                  staticClass: "file-remove",
                  on: {
                    click: function($event) {
                      return _vm.remove(index)
                    }
                  }
                },
                [_vm._v("+")]
              )
            ]
          )
        }),
        _vm.status == "ready"
          ? _c("section", { staticClass: "file-item" }, [
              _c("div", { staticClass: "add", on: { click: _vm.add } }, [
                _c("span", [_vm._v("+")])
              ])
            ])
          : _vm._e()
      ],
      2
    ),
    _vm.files.length != 0
      ? _c("section", { staticClass: "upload-func" }, [
          _c("div", { staticClass: "progress-bar" }, [
            _vm.uploading
              ? _c("section", { attrs: { width: _vm.percent * 100 + "%" } }, [
                  _vm._v(_vm._s(_vm.percent * 100 + "%"))
                ])
              : _vm._e()
          ]),
          _c("div", { staticClass: "operation-box" }, [
            _vm.status == "ready"
              ? _c("button", { on: { click: _vm.submit } }, [_vm._v("上传")])
              : _vm._e(),
            _vm.status == "finished"
              ? _c("button", { on: { click: _vm.finished } }, [_vm._v("完成")])
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _c("input", {
      ref: "file",
      attrs: { accept: "image/*", multiple: "multiple", type: "file" },
      on: { change: _vm.fileChanged }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }